import React from 'react'
import { withTranslation } from 'react-i18next';
import Navbar from './Navbar';

class ListPhotos extends React.Component {

    render() { 
    return <>

    <Navbar />

    <div className="w3-main" style={{marginLeft:"340px",marginRight:"40px"}}>
        <div className="w3-container" style={{marginTop:"80px"}}>
            <h2 className='w3-center section-font'>
                {this.props.title}
            </h2>
        </div>

        { this.props.fileName === "details" &&
        <div style={{ marginTop: "64px", marginBottom: "64px" }}>
            <a href={`${process.env.PUBLIC_URL}/photos/20230819_mariage_kim_florian.zip`}
                className='w3-button w3-text-white w3-round-xlarge w3-block theme-background-2'>
                Télécharger toutes les photos du mariage <i class="fa fa-download" aria-hidden="true"></i>
            </a>
        </div>
        }

        {(() => {
        let output = [];
        for (let index=1; index <= this.props.maxIndex; index+=2) {

            output.push(
                <div className="w3-cell-row" key={index}>

                    <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "50%"}}>
                        <img src={`${process.env.PUBLIC_URL}/photos/${this.props.directory}/${this.props.fileName}_${index}.jpg`} className="w3-image w3-round w3-card full-width" alt={index}/>
                    </div>
                    
                    { (index+1) <= this.props.maxIndex &&
                    <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "50%"}}>
                        <img src={`${process.env.PUBLIC_URL}/photos/${this.props.directory}/${this.props.fileName}_${index+1}.jpg`} className="w3-image w3-round w3-card full-width" alt={index+1}/>
                    </div>
                    }

                </div>
            );
        }
        return (output);
        })()}

        { this.props.fileName === "photobooth" && <>

            {(() => {
            let output = [];
            for (let index=1; index <= 13; index++) {

                output.push(
                    <div className="w3-cell-row" key={index}>
                        <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "100%"}}>
                            <video controls loop>
                                <source src={`${process.env.PUBLIC_URL}/photos/${this.props.directory}/${this.props.fileName}_${index+1}.mp4`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video> 
                        </div>
                    </div>
                );
            }
            return (output);
            })()}
        </> }

    </div>

</> }
}
export default withTranslation()(ListPhotos);