export default class WebpageTools {
    static displayBlock({ divId }) {
        document.getElementById(divId).style.display = "block";
    }

    static displayNone({ divId }) {
        document.getElementById(divId).style.display = "none";
    }

    static scrollTo({ event, id} ) {
        if(event) {
            event.preventDefault();
        }

        const destinatiom = document.getElementById(id);
        if(destinatiom) {
            destinatiom.scrollIntoView({
                behavior: 'smooth'
            });
        }     
    }
}