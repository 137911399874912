import './css/app.css';

import { BrowserRouter } from 'react-router-dom';

import MainRouter from './components/MainRouter';
import ScrollToTop from './core/ScrollToTop';

export default function App() {
    return (
        <div className="App" id="App">
            <BrowserRouter>

                <div id="main" className="w3-main">
                    <MainRouter/>
                </div>

                <ScrollToTop cssClassNames={`w3-btn w3-white w3-border w3-round-xlarge`}>
                    <i className="fa fa-angle-double-up" aria-hidden="true"></i>
                </ScrollToTop>
            </BrowserRouter>
        </div>
    );
}