import { Component } from 'react';

function generateCoreID() {
    const candidatesArray = [
        "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
        "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
        "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"
    ];
    const candidates = candidatesArray.join("");
    const candidatesLength = candidatesArray.length;

    let output = '';
    for( let iChar = 0 ; iChar < 64 ; ++iChar ) {
        const random = Math.floor(Math.random() * candidatesLength);
        output += `${candidates.charAt(random)}`;
    }
    output += `_${Date.now()}`;

    return output;
}

export default class CoreComponent extends Component {
    ID = generateCoreID();
    INPUT_REFS = {};

    constructor() {
        super();

        this.state = {
            timestamp: new Date(),
            danger: "",
            info: "",
            success: "",
            warning: "",
        };
    }

    updateState({ inputs, callback = null }) {
        let fields = { ...this.state };

        let shouldUpdate = false;
        for (const [key, value] of Object.entries(inputs)) {
            /* 
            Avoid inecessary updates
            Noted weakness:
                1. null and undefined will be equals
                    const a = [undefined];
                    const b = [null];
                    JSON.stringify(a) === JSON.stringify(b); // true
            */
            
            if(key in this.state) {
                let validValue = false;
                try {
                    if( JSON.stringify(fields[key]) !== JSON.stringify(value) ) {
                        validValue = true;
                    }
                } catch (e) {
                    // html object case
                    if( typeof value === "object" ) {
                        validValue = true;
                    } else {
                        console.error(e);
                    }
                }

                if(validValue) {
                    fields[key] = value;
                    shouldUpdate = true;
                }
            }
        };

        // console.log("CoreComponent", shouldUpdate, fields, this.state);
        if(shouldUpdate) {
            this.setState(fields,() => {
                if(callback) {
                    callback();
                }
            });
        }
    }

    render() { return <>
        {/* 
        <button onClick={event => this.handleClick(event, 'Hello world')}>Toggle</button> 
        format(new Date(this.state.timestamp), 'dd/MM/yyyy HH:mm:ss')
        new Intl.NumberFormat(process.env.REACT_APP_LOCALE).format(this.state.balance)
        */}
        
        { this.state.info && this.state.info.length > 0 &&
            <div className="w3-panel w3-pale-blue w3-justify">
                <p>{this.state.info}</p>
            </div>  
        }
        { this.state.success && this.state.success.length > 0 &&
            <div className="w3-panel w3-pale-green w3-justify">
                <p>{this.state.success}</p>
            </div>
        }
        { this.state.warning && this.state.warning.length > 0 &&
            <div className="w3-panel w3-pale-yellow w3-justify">
                <p>{this.state.warning}</p>
            </div>
        }
        { this.state.danger && this.state.danger.length > 0 &&
            <div className="w3-panel w3-pale-red w3-justify">
                <p>{this.state.danger}</p>
            </div>
        }
    </> }

}