import i18next from "i18next";

import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
    .use(initReactI18next)
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        nonExplicitSupportedLngs: true, // Allows "en-US" and "en-UK" to be implcitly supported when "en"
        interpolation: {
            escapeValue: false,
        },
        fallbackLng: "en",
        // debug: process.env.NODE_ENV === "development",
    });

export default i18next;