import CoreComponent from "./CoreComponent";

export default class ScrollToTop extends CoreComponent {
    componentDidMount() {
        this.handleScroll = this.handleScroll.bind(this)
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll() {
        let button = document.getElementById(this.ID);

        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            button.style.display = "block";
        } else {
            button.style.display = "none";
        }
    }

    toTop(event) {
        event.preventDefault();

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() { return <>
        <div style={{ position: "fixed", zIndex: 1, bottom: "32px", right: "32px" }}>
            <button
                onClick={event => this.toTop(event)} id={this.ID} 
                className={`${this.props.cssClassNames || ""}`}>
                {this.props.children}
            </button>
        </div>
    </> }
}