import React from 'react'
import { withTranslation } from 'react-i18next';
import BackgroundImage from '../../images/home/img_bg_2.png';
import CastleImage from '../../images/home/img_bg_3.png';
import CityHallImage from '../../images/home/img_bg_4.jpg';
import BrideImage from '../../images/home/bride.png';
import GroomImage from '../../images/home/groom.png';
import HighschoolImage from '../../images/home/highschool.jpg';
import Couple1Image from '../../images/home/couple-1.jpg';
import Couple2Image from '../../images/home/couple-2.jpeg';
import Couple3Image from '../../images/home/couple-3.jpg';
import Couple4Image from '../../images/home/couple-4.JPG';
import Couple5Image from '../../images/home/couple-5.jpeg';

class Home extends React.Component {
    
    render() { return <>

    <div className="w3-display-container w3-grayscale w3-opacity-min bg-image w3-text-white" id="home"
            style={{ 
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)), url(${BackgroundImage})`,
            }}>
        
        <div className="w3-display-middle">
            <div className='w3-center'>
                <span className="w3-padding-large w3-xlarge w3-wide">
                    <span className='title-font'
                    style={{
                        fontSize: "100px"
                    }}>
                        Kim & Florian
                    </span>
                </span>
            </div>
            <div className='w3-center' id="div-photos">
                <a  href='/photos' className='text-decoration-none'>
                    <div className='w3-btn w3-white w3-border w3-border-purple w3-round-xlarge'>
                        <p className='theme-text'>
                            <b><span >19 Août 2023 à 16H</span></b>
                        </p>
                        <p className='theme-text'>
                            Revivez le mariage en visionnant les photos !
                        </p>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <div className='w3-content'>


        <div className='section-margins'>
            <h2 className='w3-center section-font'>L ' événement</h2>

            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "50%"}}>
                    <div className='w3-card w3-padding-large w3-round w3-border event-card'>
                        <h2>
                            La cérémonie <i className="fa fa-bank" aria-hidden="true"></i>
                        </h2>
                        <div className="w3-responsive w3-center">
                            <table className="w3-table">
                                <tbody>
                                    <tr>
                                        <td className='w3-center'>16:00</td>
                                        <td className='w3-center'>19 Août 2023</td>
                                    </tr>
                                    <tr>
                                        <td className='w3-center'>4 PM</td>
                                        <td className='w3-center'>19th August 2023</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>Mairie de Boé</p>
                        <p>5 rue Guy Saint-Martin, CS 50010</p>
                        <p>47551 Boé</p>
                    </div>
                </div>
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding">
                    <img src={CityHallImage} alt="about" className='full-width w3-card-4 zoom-in-img' style={{height: "300px"}} />
                </div>
            </div>
                    
            <div className="w3-cell-row" style={{marginTop: "64px"}}>
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding">
                    <img src={CastleImage} alt="about" className='full-width w3-card-4 zoom-in-img' style={{height: "300px"}} />
                </div>
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "50%"}}>
                    <div className='w3-card w3-padding-large w3-round w3-border event-card'>
                        <h2>
                            La fête <i className="fa fa-music" aria-hidden="true"></i>
                        </h2>
                        <div className="w3-responsive w3-center">
                            <table className="w3-table">
                                <tbody>
                                    <tr>
                                        <td className='w3-center'>17:00</td>
                                        <td className='w3-center'>19 Août 2023</td>
                                    </tr>
                                    <tr>
                                        <td className='w3-center'>5 PM</td>
                                        <td className='w3-center'>19th August 2023</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>Château Saint Marcel</p>
                        <p>Route de Toulouse</p>
                        <p>47550 Boé</p>
                    </div>
                </div>
            </div>
        </div>


        <div className='section-margins'>
            <h2 className='w3-center section-font'>Contactez nous</h2>
            
            <h3 className='w3-center'>Avez-vous des questions ?</h3>
            <div className='w3-center'>
                <p>Nous recevons vos cartons réponse jusqu'au 15 juin 2023.</p>
                <p>
                    Nous mettons en ligne une <a href="https://www.mesenvies.fr/liste-mariage?r=39&led=18736162" target="blank" className='theme-link'>liste de mariage</a> ou, le jour J, une urne sera à votre disposition pour participer à notre voyage de noces. 
                </p>
                <p>
                    <b>ありがとう (Arigatô)!</b>
                </p>
            </div>

            <div className="w3-cell-row" style={{height: "300px"}}>
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "30%"}}>
                    <h3 className='subsection-font'>Kim</h3>
                    <p>62 rue du docteur Louis Brocq<br/>47550 Boé</p>
                    <p>+33 6 32 95 64 13</p>
                </div>
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "20%"}}>
                    <img src={BrideImage} alt="about" className='full-width w3-circle'/>
                </div>
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "20%"}}>
                    <img src={GroomImage} alt="about" className='full-width w3-circle'/>
                </div>
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "30%"}}>
                    <h3 className='subsection-font'>Florian</h3>
                    <p>1377 Avenue Du Bruilhois<br/>47310 Sérignac sur Garonne</p>
                    <p>+33 6 20 16 32 48</p>
                </div>
            </div>
        </div>


        <div className='section-margins'>
            <h2 className='w3-center section-font'>Notre histoire</h2>
                
            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile w3-cell-middle w3-padding" style={{"minWidth": "80%"}}>
                    <div className="timeline-panel right">
                        <div className="timeline-heading">
                            <h3 className="timeline-title">Même école...</h3>
                            <span className="timeline-date">2003 - 2007</span>
                        </div>
                        <div className="timeline-body">
                            <p>
                                Faux départ à Agen au collège Dangla: ils se sont croisés, mais ne se sont pas connus.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "20%"}}>
                    <img src={HighschoolImage} alt="about" className='w3-circle history-photo' style={{height: "150px"}}/>
                </div>
            </div>
            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "20%"}}>
                    <img src={Couple1Image} alt="about" className='w3-circle history-photo'/>
                </div>
                <div className="w3-cell w3-mobile w3-cell-middle w3-padding" style={{"minWidth": "80%"}}>
                    <div className="timeline-panel left">
                        <div className="timeline-heading">
                            <h3 className="timeline-title">Même école (encore) et même destination ?! Covoiturage !</h3>
                            <span className="timeline-date">2015 - début 2016</span>
                        </div>
                        <div className="timeline-body">
                            <p>
                                A bord de sa fusée (une Golf), Florian ramène Kim tous les weekend sur Agen. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile w3-cell-middle w3-padding" style={{"minWidth": "80%"}}>
                    <div className="timeline-panel right">
                        <div className="timeline-heading">
                            <h3 className="timeline-title">Officiellement ensemble !</h3>
                            <span className="timeline-date">19 avril 2016 (vers 20h30)</span>
                        </div>
                        <div className="timeline-body">
                            <p>
                                Les 1h30 qui séparent Bordeaux d'Agen ont rapproché Kim et Florian. 
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "20%"}}>
                    <img src={Couple2Image} alt="about" className='w3-circle history-photo'/>
                </div>
            </div>
            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "20%"}}>
                    <img src={Couple3Image} alt="about" className='w3-circle history-photo'/>
                </div>
                <div className="w3-cell w3-mobile w3-cell-middle w3-padding" style={{"minWidth": "80%"}}>
                    <div className="timeline-panel left">
                        <div className="timeline-heading">
                            <h3 className="timeline-title">Premières vacances en amoureux - Biarritz</h3>
                            <span className="timeline-date">Mai 2017</span>
                        </div>
                        <div className="timeline-body">
                            <p>
                                Au programme : balade, restaurant et musée de la mer 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile w3-cell-middle w3-padding" style={{"minWidth": "80%"}}>
                    <div className="timeline-panel right">
                        <div className="timeline-heading">
                            <h3 className="timeline-title">Disney Land Paris</h3>
                            <span className="timeline-date">Avril 2018</span>
                        </div>
                        <div className="timeline-body">
                            <p>
                                Un rendez-vous avec Mickey qu'il ne fallait pas rater ! 
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "20%"}}>
                    <img src={Couple4Image} alt="about" className='w3-circle history-photo'/>
                </div>
            </div>
            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile w3-cell-middle w3-center w3-padding" style={{"minWidth": "20%"}}>
                    <img src={Couple5Image} alt="about" className='w3-circle history-photo'/>
                </div>
                <div className="w3-cell w3-mobile w3-cell-middle w3-padding" style={{"minWidth": "80%"}}>
                    <div className="timeline-panel left">
                        <div className="timeline-heading">
                            <h3 className="timeline-title">Demande en mariage</h3>
                            <span className="timeline-date">19 avril 2022</span>
                        </div>
                        <div className="timeline-body">
                            <p>
                                Après 6 ans de relation, Florian fait sa demande ! (En plus, le repas était délicieux) 
                            </p>
                        </div>
                    </div>
                </div>
            </div>


        </div>


    </div>

    </> }
}
export default withTranslation()(Home);