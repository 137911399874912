import React from 'react'
import { withTranslation } from 'react-i18next';

import WebpageTools from '../../tools/webpage_tools/webpage_tools.js'

class Navbar extends React.Component {
    
    render() { return <>

    <nav className="w3-sidebar w3-collapse w3-top w3-large w3-padding w3-white" style={{ zIndex: 3, width: "300px", fontWeight: "bold"}} id="navbar-sidebar"><br/>
        <button onClick={event => {WebpageTools.displayNone({ divId: "navbar-sidebar" }) }} 
            className="w3-button w3-hide-large w3-display-topleft w3-text-grey theme-background-hover" style={{ width: "100%", fontSize:"22px", height: "70px"}}>
            <span className='w3-xlarge'>&times;</span>
        </button>
        <div className="w3-container">
            <a href="/home" onClick={event => {WebpageTools.displayNone({ divId: "navbar-sidebar" }) }} className='w3-bar-item w3-button w3-hover-none'>
                <h3 className="w3-padding-64 theme-text-2 section-font"><b>Kim & Florian</b></h3>
            </a> 
        </div>
        <div className="w3-bar-block">
            <a href="/photos" onClick={event => {WebpageTools.displayNone({ divId: "navbar-sidebar" }) }} 
                className="w3-bar-item w3-button w3-text-grey theme-background-hover w3-round-xlarge">
                Résumé
            </a>
            <a href="/preparatifs-mariee" onClick={event => {WebpageTools.displayNone({ divId: "navbar-sidebar" }) }} 
                className="w3-bar-item w3-button w3-text-grey theme-background-hover w3-round-xlarge">
                Préparatif de la mariée
            </a>
            <a href="/preparatifs-marie" onClick={event => {WebpageTools.displayNone({ divId: "navbar-sidebar" }) }} 
                className="w3-bar-item w3-button w3-text-grey theme-background-hover w3-round-xlarge">
                Préparatif du marié
            </a>
            <a href="/mairie" onClick={event => {WebpageTools.displayNone({ divId: "navbar-sidebar" }) }} 
                className="w3-bar-item w3-button w3-text-grey theme-background-hover w3-round-xlarge">
                La mairie
            </a>
            <a href="/photos-groupe" onClick={event => {WebpageTools.displayNone({ divId: "navbar-sidebar" }) }} 
                className="w3-bar-item w3-button w3-text-grey theme-background-hover w3-round-xlarge">
                Les photos de groupe
            </a>
            <a href="/vin-honneur" onClick={event => {WebpageTools.displayNone({ divId: "navbar-sidebar" }) }} 
                className="w3-bar-item w3-button w3-text-grey theme-background-hover w3-round-xlarge">
                Le vin d'honneur
            </a>
            <a href="/seance-couple" onClick={event => {WebpageTools.displayNone({ divId: "navbar-sidebar" }) }} 
                className="w3-bar-item w3-button w3-text-grey theme-background-hover w3-round-xlarge">
                Le couple
            </a>
            <a href="/diner" onClick={event => {WebpageTools.displayNone({ divId: "navbar-sidebar" }) }} 
                className="w3-bar-item w3-button w3-text-grey theme-background-hover w3-round-xlarge">
                Le dîner
            </a>
            <a href="/bal" onClick={event => {WebpageTools.displayNone({ divId: "navbar-sidebar" }) }} 
                className="w3-bar-item w3-button w3-text-grey theme-background-hover w3-round-xlarge">
                Le bal
            </a>
            <a href="/photobooth" onClick={event => {WebpageTools.displayNone({ divId: "navbar-sidebar" }) }} 
                className="w3-bar-item w3-button w3-text-grey theme-background-hover w3-round-xlarge">
                Le photobooth
            </a>
            <a href="/prestataires" onClick={event => {WebpageTools.displayNone({ divId: "navbar-sidebar" }) }} 
                className="w3-bar-item w3-button w3-text-grey theme-background-hover w3-round-xlarge">
                Les prestataires
            </a>
        </div>
    </nav>

    <header className="w3-container w3-top w3-hide-large w3-xlarge w3-padding theme-background-2">
        <button className="w3-button w3-margin-right w3-border w3-round w3-border-white w3-white w3-text-grey theme-background-hover" 
            onClick={event => WebpageTools.displayBlock({ divId: "navbar-sidebar" })}>
            ☰
        </button>
        <span className='w3-text-white'>Kim & Florian</span>
    </header>

    </> }
}
export default withTranslation()(Navbar);