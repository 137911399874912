import { Navigate, Route, Routes /*, useNavigate */ } from 'react-router-dom';

import Home from './Home/Home';
import ListPhotos from './Photos/ListPhotos';
import Providers from './Photos/Providers';

export default function MainRouter () {
    // const navigate = useNavigate();

    return <Routes>
        <Route index element={<Navigate to="/home" replace />} />
        
        <Route path="/home"                 element={<Home/>} />

        <Route path="/photos"               element={<ListPhotos title="Résumé"                    directory="00_details" fileName="details" maxIndex={41}/>} /> 
        <Route path="/preparatifs-mariee"   element={<ListPhotos title="Préparatif de la mariée"    directory="01_preparatifs_mariee" fileName="preparatifs_mariee" maxIndex={75}/>} /> 
        <Route path="/preparatifs-marie"    element={<ListPhotos title="Préparatif du marié"        directory="01_preparatifs_marie" fileName="preparatifs_marie" maxIndex={26}/>} /> 
        <Route path="/mairie"               element={<ListPhotos title="La mairie"                  directory="02_mairie" fileName="mairie" maxIndex={68}/>} /> 
        <Route path="/photos-groupe"        element={<ListPhotos title="Les photos de groupe"       directory="03_photosgroupe" fileName="photosgroupe" maxIndex={48}/>} /> 
        <Route path="/vin-honneur"          element={<ListPhotos title="Le vin d'honneur"           directory="04_vinhonneur" fileName="vinhonneur" maxIndex={87}/>} /> 
        <Route path="/seance-couple"        element={<ListPhotos title="Le couple"                  directory="05_seance_couple" fileName="couple" maxIndex={28}/>} /> 
        <Route path="/diner"                element={<ListPhotos title="Le diner"                   directory="06_diner" fileName="diner" maxIndex={149}/>} /> 
        <Route path="/bal"                  element={<ListPhotos title="Le bal"                     directory="07_bal" fileName="bal" maxIndex={92}/>} /> 
        <Route path="/photobooth"           element={<ListPhotos title="Le photobooth"              directory="08_photobooth" fileName="photobooth" maxIndex={189}/>} /> 
        <Route path="/prestataires"         element={<Providers/>} />

        <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
}