import React from 'react'
import { withTranslation } from 'react-i18next';
import Navbar from './Navbar';

class Providers extends React.Component {
    
    render() { return <>

    <Navbar />

    <div className="w3-main" style={{marginLeft:"340px",marginRight:"40px"}}>
        <div className="w3-container" style={{marginTop:"80px"}}>
            <h2 className='w3-center section-font'>
                Les prestataires
            </h2>
        </div>

        <h3>Un grand merci à eux !</h3>

        <div>
        <p>Décorateur et Wedding planner : <b>aLuR & Co</b> - contact@alurandco.fr</p>
        <p>Fleuriste : <b>Alviflor</b> - alviflor1@orange.fr</p>
        <p>Lieu de réception : <b>Château St Marcel</b> - contact@chateau-st-marcel.com</p>
        <p>Traiteur cocktail et repas : <b>Les inspirés</b> - contact@lesinspirestraiteur.fr</p>
        <p>Wedding Cake : <b>Icing Paradise</b> - icingparadise@gmail.com</p>
        <p>Borne photo : <b>Comme unique</b> - katy.carcanague@gmail.com</p>
        <p>Animation soirée DJ : <b>Alex Sono</b> - alexandre.barberan@orange.fr</p>
        <p>Groupe de musique : <b>Bècaflor</b> - becaflor46@gmail.com</p>
        <p>Photographe : <b>Vincent Dupin</b> - photodupin@outlook.fr</p>
        <p>Voiture des mariés : <b>M Richardo Michael</b></p>
        <p>Nounou : <b>L'or des bambins</b> - lordesbambins@gmail.com</p>
        <p>Coiffeur et maquillage : <b>Passage Bleu</b> - Parc Ccial O Green Proche Leroy Merlin, 7 All. Lacapelette Agen</p>
        <p>Robe de mariée : <b>Mademoiselle Angélique - Créatrice de Robes de Mariée sur-mesure</b> - 13 Rue Roussanes AGEN</p>
        <p>Costume du marié : <b>Blandin & Delloye</b> - 5 bis Cr de Gourgue Bordeaux</p>
        </div>
    </div>

    </> }
}
export default withTranslation()(Providers);